import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import SelectField from '@sprinx/react-mui-fields/SelectField';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import PricingConstants from '@sprinx/pricing-core/constants';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useApiClient } from '../../api/appState';
import {
  shoppingCustomerSelector,
  shoppingSelectedDeliveryPlaceState,
  shoppingSelectedShippingState,
} from '../../api/shopping';
import { shoppingCartState, useUpdateCart } from '../../api/shoppingCart';
import { getShippings, shippingsState } from '../../api/shipping/shipping';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import usePlaces from '../../hooks/usePlaces';

export interface PickDeliveryPlaceProps extends StandardDivProps<PickDeliveryPlaceClassKey> {
  extraOnChange?: (value: any) => void;
  fullWidth?: boolean;
  required?: boolean;
}

export type PickDeliveryPlaceClassKey =
  | 'root'
  | 'fullWidth'
  | 'postaddress'
  | 'paper'
  | 'label'
  | 'helperText'
  | 'error'
  | 'selectField';

const themeSettings = { name: 'PickDeliveryPlace' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<PickDeliveryPlaceClassKey, {}>({
      root: {
        paddingBottom: theme.spacing(2),
      },
      selectField: {
        [theme.breakpoints.down('sm')]: {
          '& div': {
            whiteSpace: 'initial',
          },
        },
      },
      fullWidth: {
        width: '100%',
      },
      postaddress: {
        ...theme.typography.caption,
        paddingLeft: theme.spacing(2),
      },
      paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
      },
      label: {
        ...theme.typography.h6,
        color: theme.palette.primary.main,
      },
      helperText: {
        ...theme.typography.body2,
        color: theme.palette.text.hint,
        textAlign: 'left',
      },
      error: {
        color: theme.palette.error.main,
      },
    }),
  themeSettings,
);

const PickDeliveryPlace = React.forwardRef<HTMLDivElement, PickDeliveryPlaceProps>(
  ({ className, classes: pClasses, extraOnChange, fullWidth, required = false, ...rest }, ref) => {
    const apiClient = useApiClient();
    const t = useTranslateWithFallback();
    const classes = useStyles({ classes: pClasses });
    const [selectedPlace, updateDeliveryPlace] = useRecoilState(shoppingSelectedDeliveryPlaceState);
    const places = usePlaces();
    const customer = useRecoilValue(shoppingCustomerSelector);
    const cart = useRecoilValue(shoppingCartState);
    const shipping = useRecoilValue(shoppingSelectedShippingState);
    const setShippings = useSetRecoilState(shippingsState);

    const updateCart = useUpdateCart();

    const handleChange = useCallback(
      async (e) => {
        updateDeliveryPlace(e);
        extraOnChange?.(e);
        updateCart({ product: shipping, quantity: 1, subjectId: PricingConstants.SHIPPING_ID }, e?.id);
        if (!customer || !cart) return;
        const response = await getShippings(apiClient, customer, cart?.id);

        if (!response?.rows) return;
        setShippings(response.rows);
      },
      [updateDeliveryPlace, updateCart, shipping, extraOnChange, customer, cart, apiClient, setShippings],
    );

    useEffect(() => {
      if (places.length === 0) return updateDeliveryPlace(undefined);
      if (selectedPlace || places.length !== 1) return;
      updateDeliveryPlace(places[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [places]);

    return (
      <div ref={ref} className={clsx(classes.root, { [classes.fullWidth]: fullWidth }, className)} {...rest}>
        <div className={clsx(classes.label, { [classes.error]: required && !selectedPlace })}>
          {t('app/pickDeliveryPlace/label')}
        </div>
        <div className={classes.paper}>
          <SelectField
            fullWidth
            onChange={handleChange}
            value={selectedPlace}
            required={required}
            className={classes.selectField}
            disabled={places.length === 0}
          >
            {(places || []).map((l: any) => (
              <SelectFieldItem key={l.id} value={l}>
                {l.name}
                {l.postaddresses?.length ? (
                  <span className={classes.postaddress}>{` ${l.postaddresses[0].place.full}`}</span>
                ) : null}
              </SelectFieldItem>
            ))}
          </SelectField>
        </div>
        {places.length === 0 ? (
          <div className={clsx(classes.helperText, classes.error)}>{t('app/pickDeliveryPlace/noDeliveryPlace')}</div>
        ) : (
          <div className={clsx(classes.helperText, { [classes.error]: required && !selectedPlace })}>
            {required && !selectedPlace
              ? t('app/pickDeliveryPlace/requiredError')
              : t('app/pickDeliveryPlace/helperText')}
          </div>
        )}
      </div>
    );
  },
);

PickDeliveryPlace.displayName = 'PickDeliveryPlace';

export default withThemeProps(themeSettings)(PickDeliveryPlace);
