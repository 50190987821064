import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Loader from '@sprinx/react-loader/Loader';
import DotJoined from '@sprinx/react-mui-components/DotJoined';
import IconButton from '@sprinx/react-mui-components/IconButtonWithTooltip';
import List from '@sprinx/react-mui-components/ListPretty';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import PriceFieldPreviewNaked from '@sprinx/react-mui-fields/PriceFieldPreviewNaked';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { apiClientState, currencyState } from '../../../api/appState';
import { useShowSnackbarMessage } from '../../../api/snackbars';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import CustomerProfilePageCreateMasterUserButton from './CustomerProfilePageCreateMasterUserButton';
import CustomerProfilePageUserEditDialog from './CustomerProfilePageUserEditDialog';
import useSidiaryUsersList, { SidiaryUser } from './useSubsidiaryUsersList';

export interface CustomerProfilePageMasteUsersListProps
  extends StandardDivProps<CustomerProfilePageMasteUsersListClassKey> {
  sidiary: {
    id: string;
    name: string;
  };
}

export type CustomerProfilePageMasteUsersListClassKey =
  | 'root'
  | 'actions'
  | 'backgroundRed'
  | 'backgroundGreen'
  | 'deactivatedUser';

const themeSettings = { name: 'CustomerProfilePageMasteUsersList' };
const useStyles = makeStyles(
  createStyles<CustomerProfilePageMasteUsersListClassKey, {}>({
    root: {
      border: '1px solid #E0E0E0',
      padding: '20px',
      borderRadius: '10px',
      backgroundColor: '#f3f3f3',
    },
    actions: {
      display: 'flex',
    },
    deactivatedUser: {
      background: '#DCDCDC',
    },
    backgroundRed: {
      '&:hover': {
        borderRadius: '30px',
        background: 'rgba(255, 35, 64, 0.7)',
      },
    },
    backgroundGreen: {
      '&:hover': {
        borderRadius: '30px',
        background: 'rgba(135, 211, 124, 0.7)',
      },
    },
  }),
  themeSettings,
);

const CustomerProfilePageMasteUsersList = React.forwardRef<HTMLDivElement, CustomerProfilePageMasteUsersListProps>(
  ({ className, classes: pClasses, sidiary }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const { id, name } = sidiary;
    const [loading, reloadUsers, users] = useSidiaryUsersList(id, '@testing.sprinx.cz');
    const currency = useRecoilValue(currencyState);
    const apiClient = useRecoilValue(apiClientState);
    const showMessage = useShowSnackbarMessage();
    const t = useTranslateWithFallback();

    const sortedUsers = [...users].sort((a) => (a.disabled ? 1 : -1));
    const [editDialogUser, setEditDialogUser] = React.useState<SidiaryUser | undefined>(undefined);
    const handleCloseEditUserDialog = React.useCallback(() => setEditDialogUser(undefined), [setEditDialogUser]);

    const updateUserStatus = React.useCallback(
      async (clientId, userId, disabled) => {
        await apiClient
          .post<any, { clientId: string; disabled: boolean; userId: string }>('/v1/users/updateUserStatus', {
            clientId,
            userId,
            disabled,
          })
          .then((res) => {
            if ('status' in res && (res.status === 'error' || res.status === 'warning')) {
              showMessage(res.msg, { variant: res.status }); // nefunguje s překlady...
            } else {
              showMessage(t(`app/userStatusUpdateResponse/${res.disabled ? 'deactivation' : 'activation'}`), {
                variant: 'success',
              });
              reloadUsers();
            }
          });
      },
      [apiClient, reloadUsers, t, showMessage],
    );

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Typography variant='h6'>{name}</Typography>

        <Paper>
          <Loader loading={loading}>
            <List>
              {(sortedUsers && Array.isArray(sortedUsers) ? sortedUsers : []).map((user, idx) => (
                <ListItem key={idx} className={!user.disabled ? '' : classes.deactivatedUser}>
                  <ListItemText
                    primary={user.name}
                    secondary={
                      <DotJoined>
                        {user.username}
                        {/* {user.email} */}
                        {user.orderTotalLimit ? (
                          <>
                            Limit <PriceFieldPreviewNaked currency={currency} value={user.orderTotalLimit} />
                          </>
                        ) : null}
                      </DotJoined>
                    }
                  />
                  <ListItemSecondaryAction className={classes.actions}>
                    {!user.disabled ? (
                      <IconButton
                        label={t('app/deactivateUser')}
                        className={classes.backgroundRed}
                        onClick={() => updateUserStatus(user.client, user.id, true)}
                      >
                        <HighlightOff />
                      </IconButton>
                    ) : (
                      <IconButton
                        label={t('app/activateUser')}
                        className={classes.backgroundGreen}
                        onClick={() => updateUserStatus(user.client, user.id, false)}
                      >
                        <CheckCircleOutline />
                      </IconButton>
                    )}
                    <IconButton
                      label={t('customerProfilePage/changeData/label')}
                      onClick={() => setEditDialogUser(user)}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Loader>
        </Paper>
        <CustomerProfilePageCreateMasterUserButton handleCreated={reloadUsers} sidiary={sidiary} />

        {editDialogUser && (
          <CustomerProfilePageUserEditDialog
            customerId={id}
            isMaster={true}
            open
            userDetails={editDialogUser}
            onSaved={reloadUsers}
            onClose={handleCloseEditUserDialog}
            onFormReleased={handleCloseEditUserDialog}
          />
        )}
      </div>
    );
  },
);

CustomerProfilePageMasteUsersList.displayName = 'CustomerProfilePageMasteUsersList';

export default withThemeProps(themeSettings)(CustomerProfilePageMasteUsersList);
